export interface AnalyticsQuery {
  contactId: number[];
  branchIds: number[];
  balanceIndicators: number[];
  incomeStatementIndicators: number[];
  derivedIndicators: number[];
  yearFrom: number | null;
  yearTo: number | null;
}

export interface AnalyticsQueryShort {
  balanceIndicators: number[];
  incomeStatementIndicators: number[];
  derivedIndicators: number[];
  yearFrom: number | null;
  yearTo: number | null;
}

export enum IndicatorType {
  INCOME_STATEMENT = "INCOME_STATEMENT",
  BALANCE_SHEET = "BALANCE_SHEET",
  DERIVED_INDICATORS = "DERIVED_INDICATORS",
}

export interface DeleteProjectPayload {
  projectContactId: number;
  indicatorName: string;
  indicatorType: keyof Analytics;
}

export interface DeleteProjectPayloadEnumType {
  projectContactId: number;
  indicatorName: string;
  indicatorType: IndicatorType;
}

export interface ExportAnalyticsQuery extends AnalyticsQuery {
  removedProjects: DeleteProjectPayloadEnumType[];
  showDeviation: boolean;
  fileName: string;
}

export interface AnalyticsIndicator {
  variable: string;
  data: AnalyticsIndicatorProject[];
  averageData: AnalyticsIndicatorProjectData[];
}

export interface AnalyticsIndicatorProjectData {
  year: number;
  value: number;
  ratio?: number;
}

export interface DeviationProjectData {
  year: string;
  value: number;
}

export interface AnalyticsIndicatorProject {
  projectName: string;
  projectContactId: number;
  deviation: DeviationProjectData[];
  data: AnalyticsIndicatorProjectData[];
}

export default interface Analytics {
  balanceSheet: AnalyticsIndicator[];
  incomeStatement: AnalyticsIndicator[];
  derivedIndicators: AnalyticsIndicator[];
}

export interface AnalysisComparisonKeyFigures {
  balanceSheet: string;
  derivedIndicators: string;
  incomeStatement: string;
}

export type TabItemsType =
  | "balanceSheet"
  | "derivedIndicators"
  | "incomeStatement";

export interface BuyerValuationFilterInput {
  salesPricePublishedMin?: number;
  salesPricePublishedMax?: number;
  regionIds?: number[] | null;
  branchIds?: number[] | null;
  subbranchIds?: number[] | null;
  buyerType?: number | null;
  status?: string | null;
  projectIds?: number[] | null;
  page?: number;
  itemsPerPage?: number;
  excludedContacts?: number[];
}
